// paper & background
$paper: #ffffff;

//New Dashna
$blurTextColor: #8A8D98;
$primaryTextColor: #000;
$linkColor: #5F60FF;
$btnBackground: #5F60FF;
$borderColor: #D2D5DB;
$pageBackground: #F5F5F5;
$errorBackground: rgba(235, 58, 63, 0.05);

// primary
$primaryLight: #DAE1FA;
$primaryMain: #5F60FF;
$primaryDark: #4838FC;
$primary200: #AFBCFF;
$primary800: #8B96F4;

// secondary
$secondaryLight: #DAE1FA;
$secondaryMain: #5F60FF;
$secondaryDark: #4838FC;
$secondary200: #AFBCFF;
$secondary800: #FDD560;

// success Colors
$successLight: #EAF9F1;
$success200: #95E1B9;
$successMain: #2CC474;
$successDark: #24A963;

// error
$errorLight: #FEEBEA;
$errorMain: #F73B30;
$errorDark: #CA332A;
$orangeDark: #DB5D3A;

// green
$greenFill: #2CC474;

// orange
$orangeLight: #FCECEA;
$orangeMain: #F4B4AB;
$orangeDark: #DB5D3A;

// warning
$warningLight: #FFF9E8;
$warningMain: #FDE18D;
$warningDark: #FCC31C;

// grey
$grey50: #F8FAFC;
$LightBackground: #FAFAFA;
$grey100: #F4F4F4;
$grey200: #EEEEEE;
$grey300: #DADADA;
$grey400: #c4c4c4;
$grey500: #B4B4B4;
$grey600: #8A8D98;
$grey700: #6A6E7C;
$grey900: #353D49;
$greyFigma: #6D6E6F;
$greyFigma1: #8A8D98;
$disableButton: #f6f6f6;
$disableButtonTextColor: #868A8D;
// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #333041; // level 3
$darkPaper: #1F1B32; // level 4

// dark 800 & 900
$darkLevel1: #434D5B; // level 1
$darkLevel2: #353D49; // level 2

// primary dark
$darkPrimaryLight: #DAE1FA;
$darkPrimaryMain: #5F60FF;
$darkPrimaryDark: #4838FC;
$darkPrimary200: #AFBCFF;
$darkPrimary800: #8B96F4;

// secondary dark
$darkSecondaryLight: #DAE1FA;
$darkSecondaryMain: #5F60FF;
$darkSecondaryDark: #4838FC;
$darkSecondary200: #AFBCFF;
$darkSecondary800: #4838FC;
$blueitemTitle: #5F60FF;

// text variants
$darkTextTitle: #E3E8EF;
$darkTextPrimary: #D2D5DB;
$darkTextSecondary: #C4C4C4;
$darkfigmaFontColor: #1F1B32;
$darkTextHover: #5A68FF;

// metrics new
$metricA: #5F60FF;
$metricB: #EDAE53;
$metricC: #53A580;
$metricD: #E47556;
$metricE: #9AC03C;
$metricF: #5585A1;
$metricG: #A997DD;
$metricH: #E788C1;
$metricI: #E3DE69;
$metricJ: #3FCDC5;

//---color-overrides----
:root {
  --primary-main: #5F60FF;
  --primary-dark: #4838FC;
  --primary-800: #DAE1FA;
  --rs-calendar-range-bg: var(--primary-800);
  --rs-input-focus-border: var(--primary-main);
  --rs-btn-link-text: var(--primary-main);
  --rs-btn-link-hover-text: var(--primary-dark);
  --rs-bg-active: var(--primary-main);
  --rs-btn-primary-bg: var(--primary-main);
  --rs-btn-primary-hover-bg: var(--primary-dark);
  --rs-listbox-option-hover-bg: var(--primary-800);
  --rs-listbox-option-hover-text: var(--primary-dark);
  --rs-calendar-cell-selected-hover-bg: var(--primary-dark);
}
//--------------------

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  LightBackground: $LightBackground;
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;
  greenFill: $greenFill;
  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;
  greyFignma: $greyFigma;
  greyFignma1: $greyFigma1;
  disableButton: $disableButton;
  disableButtonTextColor: $disableButtonTextColor;
  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;
  darkfigmaFontColor: $darkfigmaFontColor;
  darkTextHover: $darkTextHover;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
  blueitemTitle: $blueitemTitle;

  //New Dashna
  btnBackground: $btnBackground;
  primaryTextColor: $primaryTextColor;
  linkColor: $linkColor;
  blurTextColor: $blurTextColor;
  errorBackground: $errorBackground;
  pageBackground: $pageBackground;
  borderColor: $borderColor;


  // metrics new
  metricA: $metricA;
  metricB: $metricB;
  metricC: $metricC;
  metricD: $metricD;
  metricE: $metricE;
  metricF: $metricF;
  metricG: $metricG;
  metricH: $metricH;
  metricI: $metricI;
  metricJ: $metricJ;
}