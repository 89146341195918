@import 'themes-vars.module.scss';

.cursor-pointer {
    cursor: pointer;
}

.placeholder-text {
    color: $grey400;
}

.bg-grey-100 {
    background-color: $grey100;
}

.border-rounded-10 {
    border-radius: 10px;
}

.capitalize-text {
    text-transform: capitalize;
}


// Common Css for Entire Application

.custom-dashna-page-title {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.custom-dashna-modal-tile {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.custom-dashna-card-title {
    font-size: 16px !important;
    font-weight: 700 !important;
}

.custom-dashna-nav-item-A {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.custom-dashna-nav-item-B {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.custom-dashna-body {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.custom-dashna-body-bold {
    font-size: 16px !important;
    font-weight: 700 !important;
}

.custom-dashna-body-small {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.custom-dashna-body-small-bold {
    font-size: 12px !important;
    font-weight: 700 !important;
}

.custom-dashna-link {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.custom-dashna-link:hover {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.custom-dashna-label {
    font-size: 12px !important;
    font-weight: 600 !important;
}

.custom-dashna-text-small {
    font-size: 10px !important;
    font-weight: 400 !important;
}

.custom-dashna-text-small-bold {
    font-size: 10px !important;
    font-weight: 700 !important;
}



.link-dashna-ui {
    color: $linkColor !important;
    font-weight: 400 !important;

}

.link-dashna-ui:hover {
    color: $primaryMain !important;
    font-weight: 600 !important;

}