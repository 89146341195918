@import 'themes-vars.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

//-------------invalid link
.invalid-link-main-card {

    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);

    .invalid-link-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
            width: 400px;
        }

        .link-title {
            font-size: 16px;
            font-weight: 700;
        }

        .link-description {
            font-size: 14px;
        }
    }
}


//--------

// sign-in and sign-up css 
.sign-main {

    height: 100vh;
    min-height: 1024px;
    overflow: auto;

    .sign-in-left-side {
        background-color: $primaryDark;
        display: flex;
        align-items: center;
        justify-content: center;

        .dashna-logo {
            margin-bottom: 110px;
            margin-top: 20px;
        }

        .left-side-content-wrapper {
            height: 100vh;
            min-height: 1024px;
            max-width: 470px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @media (min-width: '2559px') {
                max-width: 600px;
            }

            @media (min-width: '3839px') {
                max-width: 750px;
            }
        }

        .title-css {
            color: $paper ;
            font-family: Inter;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .sub-title-css {
            color: $paper ;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 30px;

            @media (min-width: '2559px') {
                font-size: 20px;
            }

            @media (min-width: '3839px') {
                font-size: 24px;
            }
        }

        .sub-title-list {
            margin: 0 auto;
            color: $paper ;
            font-family: Inter;
            font-size: 16px;
            list-style-type: disc;
            margin-left: 20px;

            @media (min-width: '2559px') {
                font-size: 20px;
            }

            @media (min-width: '3839px') {
                font-size: 24px;
            }


            li {
                display: list-item;
                padding: 0;
            }
        }

        .review-card {
            border-radius: 14px;
            padding: 25px;
            background: rgba(0, 0, 0, 0.2);
            margin-top: 140px;
            min-height: 260px;
            width: 100%;
        }

        .review-card-font {
            color: $paper;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (min-width: '2559px') {
                font-size: 24px;
            }

            @media (min-width: '3839px') {
                font-size: 28px;
            }
        }
    }

    .sign-in-right-side {
        padding: 200px 0px 30px 0px;
        display: flex;
        justify-content: center;

        @media (min-width: '1919px') {
            padding: 230px 0px 40px 0px;
        }

        @media (min-width: '2559px') {
            padding: 170px 0px 30px 0px;
        }

        @media (min-width: '3839px') {
            padding: 400px 0px 30px 0px;
        }

        .right-side-content-wrapper {
            min-height: 790px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @media (min-width: '2559px') {
                justify-content: space-evenly;
            }

            @media (min-width: '3839px') {
                height: 1500px;
            }
        }

        .sign-in-right-form-container {
            width: 500px;

            @media (min-width: '2559px') {
                width: 600px;
            }

            @media (min-width: '3839px') {
                width: 750px;
            }

            fieldset {
                font-size: 14px;

                @media (min-width: '2559px') {
                    font-size: 16px;
                }

                @media (min-width: '3839px') {
                    font-size: 18px;
                }
            }
        }

        .sign-up-title {
            color: $darkfigmaFontColor;
            font-family: Inter;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (min-width: '2559px') {
                font-size: 36px;
            }

            @media (min-width: '3839px') {
                font-size: 40px;
            }
        }

        .sign-up-sub-title {
            color: $darkfigmaFontColor;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (min-width: '2559px') {
                font-size: 20px;
            }

            @media (min-width: '3839px') {
                font-size: 24px;
            }
        }

        .success-note-title {
            color: $darkPaper;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

//-----------



//Custom Loader
/* DottedLoader.css */
.dotted-loader {
    display: inline-block;
    position: relative;
    width: 100px;
    /* Adjust width to fit the larger circle */
    height: 100px;
    /* Adjust height to fit the larger circle */
}

.dot {
    position: absolute;
    width: 8px;
    /* Uniform dot size */
    height: 8px;
    /* Uniform dot size */
    border-radius: 50%;
    background: #000;
    opacity: 0;
    animation: dotted-loader 1.2s linear infinite;
}

@keyframes dotted-loader {

    0%,
    20%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

// 