// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| EMAIL VERIFICATION CSS ||============================== //
.actionBtn {
  padding: 12px 24px;
  background-color: $btnBackground;
  border-radius: 10px;
}

.actionBtnOutline {
  padding: 12px 24px;
  border-radius: 10px;
}


.overflow-auto-one {
  overflow-y: none !important;
}

.fontInter {
  font-family: Inter;
}

.fontLato {
  font-family: Lato;
}

.error-text {
  color: $errorMain !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.capitalize-text {
  text-transform: capitalize !important;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
  height: 10px !important;
  width: 10px !important;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {

    &:hover>.ps__thumb-y,
    &:focus>.ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
  &.ps--active-y>.ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y>.ps__rail-y,
  &.ps--scrolling-x>.ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

body {
  // zoom: 80%;
  overflow-y: scroll;

  font-family: 'Inter', sans-serif !important;

  @media (max-width: '1439px') {
    width: 1440px;
    overflow: auto;
  }
}

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {

  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {

  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}

.carousel-arrow {
  cursor: pointer;
  border: 0;
  display: flex;
  top: 27px;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  // border-radius: 50%;
  z-index: 1000;
  position: absolute;
  background: $primaryTextColor;
  transition: background-color 0.5s;
}

.right {
  right: -15px;
}

.left {
  left: -25px;
}

.html {
  zoom: 80%;
}

@-webkit-keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.accordion-card.open .accordion-card-content {
  max-height: 500px;
  /* Define the maximum height for the expanded content */
  transition: max-height 0.3s ease-in-out;
  /* Apply a smooth transition effect */
}

.cardWrapper {
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 3s;
  animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rs-anim-fade.rs-anim-in {
  z-index: 9999 !important;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  border-radius: 16px;
  border: 1px solid $grey400;
  // border: 1px solid rgb(0 113 220 / 31%);
  // background-color: #e5f3ff !important;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 36px;
}

.custom-daterange-picker {
  position: relative;
}

.custom-daterange-picker .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $grey900;
  font-size: 12px;
}

.custom-daterange-picker .rs-picker-toggle-active,
.custom-daterange-picker .rs-picker.rs-picker-focused {
  box-shadow: none;
  border: 2px solid $secondaryMain !important;
  font-size: 14px;
}

.custom-daterange-picker .date-range-picker-label {
  position: absolute;
  z-index: 6;
  background-color: $paper;
  border-radius: 10px;
  top: -8px;
  padding: 0px 5px;
  font-size: 12px;
  left: 14px;
  color: $grey700;

  &.compare-dates-label {
    top: -7px;
    left: 8px;
  }
}

//============================ common style=======================//
.pnl-by-item table thead tr th {
  // background-color: $pageBackground;
  background-color: #F8F8F8;
}

.pnl-by-item>div {
  scrollbar-width: thin !important;
  scrollbar-color: $grey700 $grey50 !important;


  &:hover {
    scrollbar-color: $grey600 $grey50 !important;
  }
}

.pnl-by-item table tbody tr:hover td {
  background: #efefef;
}








body::-webkit-scrollbar-track {
  background: $grey50;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background: $grey700;
}

body::-webkit-scrollbar-thumb:hover {
  background: $grey500;
}

.figma-grey-fonts {
  color: $greyFigma;
}

.font-16 {
  font-size: 16px;
}

.red-star {
  color: $errorMain;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.report-card-header {
  font-size: 1rem;
  color: $greyFigma;
  text-align: center;

  @media (max-width: '1070px') {
    font-size: 10px;
  }
}

.report-card-header-expand {
  font-size: 1rem;
  color: $greyFigma;
  text-align: left;
}

.report-card-title {
  font-size: 1.1rem;
  font-weight: 550;
  color: $greyFigma;

  @media (max-width: '1070px') {
    font-size: 11px;
  }
}

.report-card-value {
  color: $primaryTextColor;
  font-size: 1.3rem;
  font-weight: 550;
  text-align: center;
  margin-bottom: 6px;
  margin-top: 5px;

  @media (max-width: '1070px') {
    font-size: 14px;
  }
}

.report-card-value-expand {
  color: $primaryTextColor;
  font-size: 1.3rem;
  font-weight: 550;
  text-align: left;
  margin-bottom: 6px;
  margin-top: 5px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Adjust the gap as needed */
}

.info-row {
  display: flex;
  align-items: center;
}

.label {
  width: 50%;
  /* Adjust the width as needed */
  text-align: right;
  margin-right: 10px;
  /* Add spacing between the label and value */
  font-weight: bold;
}

a:hover,
a:focus {
  text-decoration: none !important;
  color: inherit !important;
}

.job-list-carousel {
  user-select: none;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-collapse: collapse;
  margin-left: 2px;
  // border: 1px solid;

  li {
    // min-width: 100px !important;
    border: solid 1px;
    border-color: $grey400;
    // padding: 20px;
  }

  .carousel-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    width: 30px;
    // border-radius: 50%;
    border: solid 1px;
    border-color: $grey400;
    background: $paper;
    z-index: 1000;
    position: absolute;
  }

  .right {
    top: 0;
    right: 0;
  }

  .left {
    top: 0;

    left: 0;
  }

  &>.react-multiple-carousel__arrow {
    height: 67px;
    width: 67px;
    background: red;

    &:hover {
      background: red;
    }
  }
}

// .job-list-carousel {
//   user-select: none;
//   margin-bottom: 25px;
//   // padding-bottom: 10px;
//   // left:20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   li {
//     // min-width: 250px !important;
//     border: solid 1px;
//     border-color: $grey400;
//     // padding: 20px;
//   }

//   .carousel-arrow {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 95px;
//     width: 41px;
//     // border-radius: 50%;
//     border: solid 1px;
//     border-color: $grey400;
//     background: $paper;
//     z-index: 1000;
//     position: absolute;
//   }

//   .right {
//     top: 0px;
//     right: 0;
//   }

//   .left {
//     top: 0px;
//     left: 0;
//   }

//   &>.react-multiple-carousel__arrow {
//     height: 67px;
//     width: 67px;
//     background: red;

//     &:hover {
//       background: red;
//     }
//   }
// }

.afterBorder {

  &:before,
  &:after {
    display: none;
  }
}

.bulk-opration-modal .MuiDialog-container {
  display: block !important;
  max-width: 800px;
  margin: 0 auto;
}

.bulk-opration-modal .MuiPaper-root::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.bulk-opration-modal .MuiPaper-root::-webkit-scrollbar-track {
  background: $grey50;
  border-radius: 5px;
}

.bulk-opration-modal .MuiPaper-root::-webkit-scrollbar-thumb {
  background: $grey400;
  border-radius: 5px;
}

.bulk-opration-modal .MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background: $grey300;
  border-radius: 5px;
}

.bulk-opration-modal .MuiBox-root::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.bulk-opration-modal .MuiBox-root::-webkit-scrollbar-track {
  background: $grey50;
  border-radius: 5px;
}

.bulk-opration-modal .MuiBox-root::-webkit-scrollbar-thumb {
  background: $grey400;
  border-radius: 5px;
}

.bulk-opration-modal .MuiBox-root::-webkit-scrollbar-thumb:hover {
  background: $grey300;
  border-radius: 5px;
}

.bulk-opration-modal-circular {
  width: 20px;
  height: 20px;
  position: sticky;
  margin: 0px;
  top: 50%;
  left: 50%;
}

#basic-menu .MuiPaper-root {
  border: 1px solid $grey300;
}

.item-dropdown-wrapper {
  position: relative;

  .item-dropdown {
    width: 50%;
    position: absolute;
    right: 7px;
    background: $paper;
    color: $primaryTextColor;
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    z-index: 9;
    bottom: -95px;

    .action {
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 15px;
      width: 100%;
      justify-content: flex-start;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 14px;
      width: 10px;
      height: 10px;
      background-color: rgb(255, 255, 255);
      transform: translateY(-50%) rotate(45deg);
      z-index: 99;
    }
  }
}

.chip-dropdown-block {
  width: 100%;
  display: flex;

  .chip-dropdown-wrraper {
    position: relative;

    .chip-dropdown {
      background: $paper;
      border-radius: 4px;
      padding: 10px;
      position: absolute;
      z-index: 9;
      width: 360px;
      top: 40px;
      height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);

      button {
        border-radius: 20px;
        padding: 4px 10px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -3px;
        left: 14px;
        width: 10px;
        height: 10px;
        background-color: rgb(255, 255, 255);
        transform: translateY(-34%) rotate(45deg);
        z-index: 99;
      }
    }
  }
}

.custom-report-table {
  .MuiTableBody-root {
    tr {
      text-align: center !important;

      td {
        border: 1px solid $grey500 !important;
        text-align: center !important;
        background: $paper !important;

        &:first-child {
          position: sticky;
          background: $paper !important;
          left: 0;
          z-index: 999;
        }
      }
    }
  }

  .MuiTableHead-root {
    th {
      text-align: center;
      padding: 0px 10px;
      border: 1px solid $grey500;
      background: $paper !important;

      &:first-child {
        position: sticky;
        background: $paper !important;
        left: 0;
        z-index: 999;
      }

      span {
        justify-content: center;
      }
    }
  }
}

.search-textfiled {
  width: 100%;
  margin-top: 10px;

  .MuiInputBase-root {
    height: 40px;

    input {
      height: 40px;
      padding: 0px;
      border-radius: 5px;
    }

    fieldset {
      border-radius: 10px;
    }
  }
}

.popup-view {
  min-width: 150px;
  padding: 10px;
  max-height: 200px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.2em;
    background-color: $grey100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey600;
  }
}

.item-dropdown-wrapper .item-dropdown-measure {
  bottom: -200%;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 10px;
}

input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  border: 2px solid $grey400;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border: 1px solid $grey400;
  border-radius: 8px;
  line-height: 25px;
  height: 100%;
}

.rs-picker-toggle-wrapper {
  height: 100%;
}

.custom-auto .MuiFormControl-root {
  width: 100%;
}

.mui-select-color .MuiNativeSelect-select {
  background-color: $secondaryMain;
  border: "none !important"
}

.Custom-dropDown-bulk {
  background-color: $primaryMain;
  color: $paper;
  border-radius: 8px;
  padding: 12px 14px;
  font-weight: 400;
}

.Custom-dropDown-bulk:hover {
  background-color: $primaryDark;
}

.Custom-menu-bulk ul.MuiList-root {
  width: 148px;
}

.white-Space {
  white-space: nowrap;
  padding-right: 1px;
}

.for-width .MuiDialog-paper {
  max-width: 750px !important;
}

.filter-performance-padding {
  padding: 3px 14px !important;
}

table tbody tr td:nth-child(1),
table tbody tr td.second-sticky-column:nth-child(2) {
  background-color: white;

  @media (max-width: 1440px) {
    padding: 2px 2px 2px 0px !important;
  }
}


.custom-campaign-table table tbody tr td:nth-child(3)>div,
.custom-campaign-table table thead tr th:nth-child(3)>span {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 34px);
    position: absolute;
    right: -16px;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-campaign-table table tfoot tr td:nth-child(3) {
  position: relative;
  position: sticky;
  left: 410px;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    position: absolute;
    right: 0;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-campaign-table table thead tr th:nth-child(3)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-campaign-table table tbody tr:hover td {
  background: $grey200 !important;
}

.custom-campaign-table table tbody tr:hover td {
  background: $grey200 !important;
}

// ad group table css
.custom-adgroup-table table tbody tr td:nth-child(2)>div,
.custom-adgroup-table table thead tr th:nth-child(2)>span {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 45px);
    position: absolute;
    right: -16px;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-adgroup-table table tfoot tr td:nth-child(2) {
  position: relative;
  position: sticky;
  left: 380px;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    position: absolute;
    right: 0;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-adgroup-table table thead tr th:nth-child(3)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-adgroup-table table tbody tr:hover td {
  background: $grey200 !important;
}

.custom-adgroup-table table tbody tr:hover td {
  background: $grey200 !important;
}


// Keyword table
.custom-keyword-table table tbody tr td:nth-child(3)>div,
.custom-keyword-table table thead tr th:nth-child(3)>span {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 34px);
    position: absolute;
    right: -16px;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}



.custom-keyword-table table tfoot tr td:nth-child(3) {
  position: relative;
  position: sticky;
  left: 380px;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    position: absolute;
    right: 0;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-keyword-table table thead tr th:nth-child(3)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-keyword-table table tbody tr:hover td {
  background: $grey200 !important;
}

.custom-keyword-table table tbody tr:hover td {
  background: $grey200 !important;
}


// items page for class 1

.custom-item-table table tbody tr td:nth-child(3)>div,
.custom-item-table table thead tr th:nth-child(3)>span {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 34px);
    position: absolute;
    right: -16px;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}


.custom-item-table table tfoot tr td:nth-child(3) {
  position: relative;
  position: sticky;
  left: 380px;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    position: absolute;
    right: 0;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}


.custom-item-table table thead tr th:nth-child(3)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-item-table table tbody tr:hover td {
  background: $grey200 !important;
}

.custom-item-table table tbody tr:hover td {
  background: $grey200 !important;
}


// item page 2 class 2
.custom-item-2-table table tbody tr td:nth-child(2)>div,
.custom-item-2-table table thead tr th:nth-child(2)>span {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 45px);
    position: absolute;
    right: -16px;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}


.custom-item-2-table table tfoot tr td:nth-child(2) {
  position: relative;
  position: sticky;
  left: 41px;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    position: absolute;
    right: 0;
    top: -16px;
    border-right: 1px solid $grey200;
  }
}

.custom-item-2-table table thead tr th:nth-child(3)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-item-2-table table tbody tr:hover td {
  background: $grey200 !important;
}

.custom-item-2-table table tbody tr:hover td {
  background: $grey200 !important;
}

.tss-1qjwhn0-MUIDataTableBody-emptyTitle {
  text-align: start !important;
}

.custom-table-scroll {
  height: 300px;
  overflow-y: scroll;
  overflow-x: visible;

  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: $grey400;
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey600;
    outline: 1px solid $grey700;
  }
}

.performance-wrapper {
  .performance-outline {
    width: 100%;

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted $primaryTextColor;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $primaryTextColor;
  color: $paper;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dorpdown-text-trim {
  max-width: 75%;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.Bid-tooltip {
  .bid-multplier-popover {
    padding: 10px 0px;

    .bid-multplier-table {
      max-height: 330px;
      width: 568px;
      overflow-y: auto;
    }
  }
}

.custom-sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  white-space: nowrap;
  background-color: $paper;
}

// budget table css

//When We Remove Select All Checkbox
.custom-budget-table-no-checkbox table tbody tr td:nth-child(1)>div,
.custom-budget-table-no-checkbox table thead tr th:nth-child(1)>div {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 53px);
    position: absolute;
    right: -16px;
    top: -27px;
    border-right: 1px solid #e0e0e0ed;
  }
}

.custom-budget-table-no-checkbox table thead tr th:nth-child(1)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-budget-table-no-checkbox table tbody tr:hover td {
  background: #efefef;
}

.custom-budget-table-no-checkbox table tbody tr:hover td {
  background: #efefef;
}


.custom-budget-table table tbody tr td:nth-child(2)>div,
.custom-budget-table table thead tr th:nth-child(2)>div {
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% + 53px);
    position: absolute;
    right: -16px;
    top: -27px;
    border-right: 1px solid $grey200;
  }
}

.custom-budget-table table thead tr th:nth-child(2)>span {
  &::after {
    height: calc(100% + 60px);
  }
}

.custom-budget-table table tbody tr:hover td {
  background: $grey200;
}

.custom-budget-table table tbody tr:hover td {
  background: $grey200;
}

.info-forward {
  color: $grey500;
}

.dateCalenderx {
  width: 100%;

  .rdrDays,
  .rdrWeekDays,
  .rdrMonthName,
  .rdrMonth {
    width: 230px;
  }

  .rdrMonth:nth-child(1) {
    padding: 0px;
    border-right: 1px solid $grey200;
  }

  .rdrStaticRangeSelected,
  .rdrStaticRangeSelected:hover,
  .rdrStaticRange:hover,
  .rdrStaticRangeLabel:hover {
    color: $primaryMain !important;
  }

  padding: 0px !important;

  >div:nth-child(1) {
    width: 165px;

    >div:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 870px) {
    flex-direction: column;

    div {
      width: 100% !important;
    }

    div:nth-child(1) {
      div {
        flex-direction: row;
      }

      div:nth-child(1) {
        display: block;

        button {
          float: left;
        }
      }
    }

    >div:nth-child(2)>div:nth-child(2) {
      flex-direction: column;
    }
  }
}

.compare-date-picker-wrapper {
  padding: 16px;
  background-color: $paper;
  width: 700px;

  .rs-picker-default .rs-picker-toggle.rs-btn {
    line-height: unset;

    .rs-picker-toggle-caret {
      top: 5px;
    }
  }

  .current-period-dates {
    padding-bottom: 12px;

    .compare-switch {
      margin-right: 0px;

      .MuiTypography-root {
        font-weight: 700;
        margin-right: 8px;
        color: $greyFigma;
      }
    }
  }

  .rdrCalendarWrapper {
    padding: 0px 20px;
  }
}

.table-align-right {
  text-align: right;

  p {
    display: flex;
    justify-content: flex-end;
    line-height: 13px;
  }
}

.table-align-left {
  text-align: left;

  p {
    justify-content: flex-start;
  }
}

.date-picker-wrapper {
  button {
    color: $grey500;
    border-radius: 8px;
    border-color: $grey400;
    height: 38px;

    .MuiTypography-root {
      font-size: 16px;
    }

    img {
      margin-top: -2px;
    }
  }
}

.date-range-selector-dropdown {
  height: 35px;
  width: 160px;

  .MuiSelect-select {
    background-color: $paper;
  }

  fieldset {
    border-radius: 8px;
  }
}

#simple-popover {
  .MuiPaper-root {
    box-shadow: 0 0 6px 0px;
  }
}

.table-footer-total {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: rgba(224, 224, 224, 0.9294117647);
    z-index: 1000;
  }
}

.badge-size .MuiBadge-badge {
  padding: "2px !important";
}

.circle-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $primaryMain;
  color: $paper;
  font-size: 0.75rem;
  margin-left: 8px;
}


//+++++++++++++++dashbard css+++++++++++++++++++++++++++++++++++++++

.top-product-table {
  .MuiTableCell-root {
    //padding-right: 2px !important;
    padding: 4px 10px;
    // padding: 4px;
    font-weight: 700 !important;
    text-align: right;

    .MuiButtonBase-root {
      font-size: 10px;
      font-weight: 700;
      color: $darkfigmaFontColor;
      text-align: left;
    }
  }

  .MuiTableBody-root {
    tr {

      td {
        font-weight: 400 !important;
        border-bottom: 1px solid $grey100 !important;
        border-right: 1px solid $grey100 !important;
        background: $paper !important;
        font-size: 14px !important;
        padding: 4px 10px !important;
        color: $darkfigmaFontColor;
        text-align: left !important;
        width: 50px;
      }

      td:last-child {
        border-right: none !important;
      }

      td:first-child {
        padding-left: 0px !important;
      }
    }
  }

  .MuiTableHead-root {
    th {
      font-size: 10px !important;
      border-bottom: 2px solid $grey100;
      border-right: 2px solid $grey100;
      background: $paper !important;
      font-size: 14px !important;
      color: $darkfigmaFontColor;
      width: 50px;
      padding: 4px 10px !important;

      span {
        justify-content: flex-end;

        button {
          padding: 0;
          margin: 0;
        }
      }

      /* Ensure all header cells are aligned left */
    }

    th:last-child {
      border-right: none !important;
    }
  }

  .top-product-img {
    img {
      height: 40px;
      width: 40px;
      padding: 4px;
      border-radius: 8px;
      border: 1px solid $grey200;
      margin-right: 8px;
    }
  }

  .top-product-item-title {
    color: $blueitemTitle;
    font-size: 14px;

    // @media (min-width: '1919px') {
    //   font-size: 14px;
    // }

    // @media (min-width: '2559px') {
    //   font-size: 14px;
    // }
  }
}

.top-product-table {
  table {
    height: 100%;
    width: 98%;

    thead {
      th:first-child {
        text-align: left;
        padding-left: 0px !important;

        span {
          justify-content: flex-start;

        }
      }
    }

    .MuiToolbar-root {
      padding: 0px;
    }
  }
}

.top-product-select {
  .MuiSelect-select {
    font-size: 10px;
    font-weight: 400;
    padding: 7px 11px !important;
  }

}

.top-product-value-dropdown {
  font-size: 14px;
}

.performance-comparison-table {
  min-width: 100%;

  .table-align-right {
    p {
      line-height: normal !important;
      font-size: 14px !important;
    }
  }

  table {
    max-width: 99%;
  }

  .MuiTableCell-root {
    padding: 0px;
    font-weight: 700 !important;
    text-align: left;
    /* Align all cells to the left by default */

    @media (min-width: '1919px') {
      padding: 2px 10px;
    }

    @media (min-width: '2559px') {
      padding: 2.5px 10px;
    }

    .MuiButtonBase-root {
      font-size: 10px;
      font-weight: 700;
      color: $darkfigmaFontColor;
      text-align: left;
    }
  }

  .MuiTableBody-root {
    tr {
      td {
        font-weight: 400 !important;
        border-bottom: 2px solid $grey100 !important;
        border-right: 2px solid $grey100 !important;
        background: $paper !important;
        // font-size: 10px !important;
        color: $darkfigmaFontColor;
        text-align: left !important;
        width: 40px;
        padding: 4px 10px;
        /* Ensure all table body cells are aligned left */


        @media (max-width: '1440px') {
          padding: 4px 2px;
        }
      }

      td:last-child {
        border-right: none !important;
      }
    }
  }

  .MuiTableHead-root {
    th {
      font-size: 14px !important;
      color: $darkfigmaFontColor;
      border-bottom: 2px solid $grey100;
      border-right: 2px solid $grey100;
      background: $paper !important;
      text-align: left !important;
      padding: 4px 10px;
      width: 40px;

      @media (max-width: '1440px') {
        padding: 4px 2px;
      }

      // @media (min-width: '1919px') {
      //   font-size: 14px !important;
      // }

      // @media (min-width: '2559px') {
      //   font-size: 14px !important;
      // }

      div {
        display: flex;
        justify-content: flex-end;

        button {
          padding: 0;
          margin: 0;
        }
      }

      /* Ensure all header cells are aligned left */
    }

    th:last-child {
      border-right: none !important;
    }
  }
}



.performance-comparison-table {
  table {
    thead {
      th:first-child {
        padding-left: 0px !important;

        div {
          display: flex;
          justify-content: flex-start;

        }
      }
    }
  }
}

.performance-comparison-table,
.top-product-table {
  table {
    tbody {
      tr {
        td {
          &:nth-child(1) {
            padding-left: 0px;

            div {
              font-size: 14px;
              // font-weight: 700;

              // @media (min-width: '1919px') {
              //   font-size: 14px;
              // }

              // @media (min-width: '2559px') {
              //   font-size: 14px;
              // }
            }
          }
        }
      }
    }
  }
}

.font-size-overview {
  font-size: 14px;
  color: $darkfigmaFontColor;

  // @media (min-width: '1919px') {
  //   font-size: 14px;
  // }

  // @media (min-width: '2559px') {
  //   font-size: 14px;
  // }
}

.white-paper-card-dashboard {
  background-color: $paper;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
  width: 98%;
  min-height: 448px;

  .MuiDivider-root {
    border: 0.5px solid $grey300;
    display: none;
  }

  @media (min-width: '1535px') {
    min-height: 538px;
  }

  @media (min-width: '1919px') {
    min-height: 557px !important;
  }

  &.table-card {
    min-height: 617px !important;
    position: relative;

    @media (max-width: '1535px') {
      min-height: 628px !important;
    }

    .MuiToolbar-root {
      position: absolute;
      right: 20px;
      bottom: 0px;
      width: 100%;
      z-index: 100;
    }
  }

  &.chart-card {

    @media (min-width: '2159px') {
      min-height: 610px !important;

      .table-align-right {
        p {
          line-height: 16px;
        }
      }
    }

    @media (min-width: '2559px') {
      min-height: 660px !important;
    }
  }

  .custom-card {
    border-top-width: 3px;
    border-top-style: solid;
    border-radius: 6px;
    margin: 0 0px;
    flex: 1;
    position: relative;
    overflow: unset;

    &::before {
      position: absolute;
      content: '';
      left: -8px;
      top: 0px;
      width: 1px;
      height: calc(100% + 0px);
      background-color: $grey300;
    }

    @media (max-width: '1440px') {
      min-width: 109px;
      max-width: 109px;
    }

    @media (min-width: '1441px') {
      min-width: 155px;
      max-width: 155px;
    }
  }

  .metric-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 8px;
    cursor: pointer;

    @media (max-width: '1440px') {
      padding: 10px 5px;

      .dashboard-card-compare-data {
        .dashboard-card-text {
          font-size: 11px;
        }
      }
    }

    @media (min-width: '1441px') {
      min-width: 145px;
      max-width: 145px;
    }
  }

  .metric-title {
    color: $greyFigma;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 4px;
    //min-height: 22px;

    // @media (min-width: '1919px') {
    //   font-size: 14px !important;
    // }
  }

  .metric-value {
    color: $darkfigmaFontColor;
    font-size: 20px;
    line-height: normal;
    margin-bottom: 4px;
    font-weight: 700;
  }

  .metric-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      display: flex;
      margin-right: 2px;

      svg {
        font-size: 18px;
      }
    }
  }

  .metric-percentage {
    font-size: 10px;
    margin-right: 2px;
  }

  .matric-amount {
    font-size: 10px;
  }

  .success {
    color: $successMain;
  }

  .error {
    color: $errorMain;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    gap: 12px;

    @media (min-width: '1441px') {
      gap: 16px;
    }
  }

  .dashboard-chart {
    .MuiIconButton-root {
      padding: 0px;
      margin: 0px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 0;

      span {
        line-height: 0;
      }

      img {
        width: 20px;
        line-height: 0;
        height: 20px;

        @media (max-width: '1440px') {
          width: 18px;
          height: 18px;
        }
      }
    }

    .dashboard-chart {
      min-height: auto !important;
    }
  }

  .chart-icon {
    // margin-right: 8px;
    // width: 12px;
  }

  .apexcharts-menu-icon {
    position: absolute;
    right: 40px;
    top: -40px;
    opacity: 0;
  }

  .filter-select {
    margin: 0px 8px;
    min-width: 75px;
    color: $greyFigma1;
  }

  .ad-performance-select {
    height: 40px;
    padding: 4px 34px 4px 12px !important;

    &.Mui-focused fieldset {
      border: 1px solid $greyFigma1;
    }

    .MuiSelect-select {
      font-size: 14px;
      font-weight: 400;
      background-color: $paper;
      color: $greyFigma1;
    }

    fieldset {
      &:hover {
        border-color: $greyFigma1;
      }

      border-radius: 6px;
    }
  }

  .dashboard-card-compare-data {
    .dashboard-card-arrow {
      font-size: 10px;
      width: 18px;
      height: 18px;
    }

    .dashboard-card-text {
      font-size: 14px;
      display: flex;
      gap: 3px;
      margin-left: 3px;

      @media (min-width: '1441px') {
        gap: 5px;
      }
    }

  }

  .dashboard-card-title {
    font-size: 16px;
    font-weight: 700;
    color: $darkfigmaFontColor;
  }

  .dashboard-card-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $greyFigma1;
  }
}

.MuiModal-root .white-paper-card-dashboard {
  min-height: auto;
  width: 100%;

  .custom-card {
    min-width: 145px;
  }
}

//-----
.share-link-wrapper {
  .font-16 {
    .MuiTypography-root {
      font-size: 16px;
    }
  }

  .expirydate-radio {
    height: 60px;
  }

  .custom-expiry-date-picker {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .rs-picker-default .rs-picker-toggle.rs-btn {
      line-height: 30px;
      height: 40px;
    }

    .rs-picker-toggle-textbox {
      font-size: 16px;
    }

    .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
      top: 8px !important;
    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
      font-size: 16px;
    }
  }

  .multiline-textfield {
    .MuiOutlinedInput-root {
      font-size: 16px;
    }
  }
}

h2.Overview-title {
  color: rgb(31, 27, 50);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  margin-bottom: 18px;
  margin-left: 10px;
}

.custom-compare-date-picker .rs-stack {
  flex-direction: row !important;
}

.top-product-value-dropdown {
  justify-content: space-between;
  padding-left: 8px;
}

.top-product-value-dropdown.Mui-selected {
  background-color: $primaryLight;
  color: $primaryMain;
  position: relative;
  font-weight: 700;

  &:hover {
    background-color: $primaryLight;
    color: $primaryMain;
  }

  &:after {
    position: absolute;
    content: '';
    background-image: url("../images/Checked.svg");
    right: 2px;
    top: 10px;
    background-repeat: no-repeat;
    width: 20px;
    height: 100%;
  }
}

.dashboard-chart .apexcharts-tooltip .apexcharts-tooltip-marker {
  width: 20px;
  height: 20px;
}

.apexcharts-canvas {

  .apexcharts-tooltip {
    .apexcharts-tooltip-marker {
      border-radius: 3px;
      width: 12.57px !important;
      height: 10px !important;
    }

    .apexcharts-tooltip-title {
      font-size: 10px !important;
      font-weight: 400;
      line-height: 11.72px;
      text-align: left;
      background: transparent !important;
      border: none !important;
      color: $greyFigma1;
      padding: 12px 16px;
      margin: 0px !important;
    }


    &.apexcharts-theme-light:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -16px;
      left: 15%;
      box-sizing: border-box;
      border: 8px solid $darkPaper;
      border-color: transparent transparent $paper $paper;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: -4px 4px 3px 0 rgb(0 0 0 / 12%);
    }

    &.apexcharts-tooltip {
      // opacity: 1 !important;
      overflow: visible;
      min-width: 120px !important;
    }
  }

  .apexcharts-tooltip-text {
    width: 100%;

    .apexcharts-tooltip-y-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .apexcharts-tooltip-series-group {
    justify-content: space-between !important;
    height: 14px;
    margin-bottom: 8px;

    .apexcharts-tooltip-text-y-label {
      font-size: 10px;
      color: $darkfigmaFontColor;
    }

    .apexcharts-tooltip-text-y-value {
      font-size: 12px;
      color: $darkfigmaFontColor;
    }

  }

  .apexcharts-legend {
    margin-left: 0px;
    padding-left: 0px;
    bottom: 0px !important;
    overflow: inherit !important;


    .apexcharts-legend-series {
      display: flex !important;
      align-items: center !important;
      margin: 4px 16px 0px 0px !important;


      span {
        border-radius: 3px !important;
        color: $greyFigma !important;
        line-height: normal !important;
        font-size: 14px !important;

        // @media (min-width: '1919px') {
        //   font-size: 14px !important;
        // }

        // @media (min-width: '2559px') {
        //   font-size: 14px !important;
        // }
      }
    }
  }

  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    font-family: 'Roboto' !important;
    font-weight: 400;
  }

  .apexcharts-xaxis-label {
    fill: $greyFigma1;
    font-size: 14px;

    // @media (min-width: '1919px') {
    //   font-size: 12px !important;
    // }

    // @media (min-width: '2559px') {
    //   font-size: 14px !important;
    // }
  }

  .apexcharts-yaxis-label {
    // fill: $greyFigma1;
    font-size: 14px;

    // @media (min-width: '1919px') {
    //   font-size: 14px !important;
    // }

    // @media (min-width: '2559px') {
    //   font-size: 14px !important;
    // }
  }
}

.Dashboard-skeleton {
  background-color: $primaryLight;
  padding: 20px;

  .bg-white {
    background-color: $paper;
    border-radius: 12px;
    padding: 16px;

    .skeleton-parent {
      height: calc((100vh / 2) - 85px);
      width: 100%;

      .skeleton {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.no-advertiser {
  color: $greyFigma1;
  font-size: 14px;
}

.setMarginRight {
  margin-right: 1.7rem
}


.height-content {
  min-height: 100vh;
}

//For Header Profile Dropdown Css
.profileDrp .MuiOutlinedInput-root {
  padding-top: 1px;
  padding-bottom: 1px;
}

.profileDrp .MuiInputLabel-root {
  top: -6px;
}

.profileDrp .MuiInputLabel-shrink {
  top: 0px !important;
}

.sign-up-success {
  color: $darkfigmaFontColor;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.column-hide {
  .mui-datatables .MuiTableCell-head:nth-child(2) {
    display: none;
  }
}

.Custom-multiline-autocomplete {
  .MuiChip-root {
    background-color: transparent;

    svg {
      display: none;
    }
  }
}

.custom-table-border {
  border: 1px solid $grey200;
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 4px;

  .MuiTableCell-root {
    border: 1px solid $grey200;
    border-top: 0 !important;
    border-left: 0 !important;
    background-color: $paper !important;
    /* Border around each cell */
  }

  /* If you want to remove the default MUI border spacing, you can add this: */
  .MuiTable-root {
    border-collapse: collapse;
    border-left: 0 !important;
    /* Ensures borders don't double up */
  }

  /* Optional */
}

.custom-table-border-selected {
  border: 1px solid $grey200;
  border-radius: 4px;

  .MuiTableCell-root {
    // border: 1px solid $grey200;
    background-color: $paper !important;
    /* Border around each cell */
  }

  /* If you want to remove the default MUI border spacing, you can add this: */
  // .MuiTable-root {
  //   border-collapse: collapse;
  //   /* Ensures borders don't double up */
  // }

  /* Optional */
}

// ------- maintanance page 
.maintanace-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: $paper;

  .maintanace-fonts {
    font-weight: 700;
    font-family: Inter;
  }

  .maintanace-img {
    max-width: 100%;
    height: auto;
    margin-bottom: 32px;
  }
}

//=========================
//Font Color
.clr1 {
  color: #8F9091;
}

.clr2 {
  color: #0B081B;
}

.clr3 {
  color: #1E1F21;
}

.clr4 {
  color: #FFA600;
}


//Font Weight
.fw400 {
  font-weight: 400;
}

.fw700 {
  font-weight: 700;
}

.fw600 {
  font-weight: 600;
}

//BorderRadius
.br6 {
  border-radius: 6px;
}

//Font Family
.ffInter {
  font-family: 'Inter';
}

//Font Size
.fs24 {
  font-size: 24px;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs20 {
  font-size: 20px;
}


.tabs-width {
  border-bottom: 1px solid $grey200 !important;

  div {
    div {
      width: 100%;
      max-width: 233px !important;
      border-bottom: #0B081B !important;

    }
  }
}

.CustomSwitchToggle {
  margin-right: 16px;
  margin-left: 0px;
}

.heightAuto {
  height: auto !important;
}



.sidebarCollapsed {
  .scrollbar-container {
    .MuiButtonBase-root {
      padding: 12px 12px;

      .MuiListItemIcon-root {
        svg {
          font-size: 18px;
        }
      }

      .MuiListItemText-root {
        display: none;
      }
    }

    .MuiCollapse-root {
      display: none;
    }
  }
}



.sidebarHover {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 1000 !important;
  min-width: 245px !important;
}

//BI-Dashboard

.BI-dashboard-card {
  background-color: $paper;
  height: calc(100vh - 130px);
  width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 12px;

  iframe {
    border: none;
    height: calc(100vh - 200px);
    width: 100%;
  }
}

//---------------------

//--------------rules engine Styles--------------

.ppc-rules {
  .all-condition-wrapper {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

    .condition-top {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;

      .condition-add-buttons {
        gap: 10px;
        display: flex;
      }
    }

    .conditions-wrapper {
      margin-bottom: 10px;

      .condition-fields {
        margin-left: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 12px;
      }
    }

    .sub-condition-wrapper {
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
      margin: 16px 2px;
      padding: 8px;

      .sub-condition-top {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: 6px 0px;

        .sub-condition-add-delete-buttons {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .all-actions-wrapper {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  }
}

//-------------------------------------------

.externalDialogBackClr {
  .MuiDialog-container {
    background-color: $pageBackground;
  }
}


//------------------------------------
.campaign-step-card {
  padding: 24px;
  margin-top: 24px;
  border-radius: 10px;
  border: 1px solid #D2D5DB;

  .campaign-card-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
  }

  .sp-multipliers-container {
    border: 1px solid #eee;
    margin: 16px -4px;
    padding: 16px;
    border-radius: 8px;
  }

  .radio-group {
    .radio-item {
      border-radius: 8px;
      border: 1px solid #C4C4C4;
      padding: 0px 20px;
      width: 100%;
      margin: 0px;

    }

    .radio-item:has(.Mui-checked) {
      background: rgba(95, 96, 255, 0.05);
      border: 1px solid #AFAFFF;
    }
  }

  .campaignAutocomplete {
    .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
      background-image: url('../images/icons/search.svg') !important;
      padding: 4px;
    }

    .MuiAutocomplete-popupIndicator {
      transform: rotate(0deg);
    }
  }
}